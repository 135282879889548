import { ApiProvider } from '@reduxjs/toolkit/query/react'
import React, { ReactElement } from 'react'
import { petApi } from '../generated/store/petApi'
import VexoftPlaceholder from '../modules/components/VexoftPlaceholder'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { appRoutes } from './routing/routes'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { appTheme } from './styles/theme'
import HeaderBar from '../modules/components/header-bar/HeaderBar'
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web'
import keycloak from '../modules/security/keycloak'

const KeycloakLoadingSuspense: React.FC<{ children: ReactElement[] | ReactElement }> = ({ children }) => {
  const { initialized } = useKeycloak()

  // TODO add a timeout
  if (!initialized)
    return (
      <div>Loading authentication provider...</div>
    )

  return children
}

const appRouter = createBrowserRouter(appRoutes)
export const App: React.FC = () => {
  return (
    // TODO remove the api provider
    <ApiProvider api={petApi}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <ReactKeycloakProvider authClient={keycloak}>
          <React.StrictMode>
            <KeycloakLoadingSuspense>
              <RouterProvider router={appRouter} />
            </KeycloakLoadingSuspense>
          </React.StrictMode>
        </ReactKeycloakProvider>
      </ThemeProvider>
    </ApiProvider>
  )
}
