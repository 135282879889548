import { emptySplitApi as api } from "../../modules/store/emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listPets: build.query<ListPetsApiResponse, ListPetsApiArg>({
      query: (queryArg) => ({
        url: `/pets`,
        params: { limit: queryArg.limit },
      }),
    }),
    createPets: build.mutation<CreatePetsApiResponse, CreatePetsApiArg>({
      query: (queryArg) => ({
        url: `/pets`,
        method: "POST",
        body: queryArg.pet,
      }),
    }),
    showPetById: build.query<ShowPetByIdApiResponse, ShowPetByIdApiArg>({
      query: (queryArg) => ({ url: `/pets/${queryArg.petId}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as petApi };
export type ListPetsApiResponse = /** status 200 A paged array of pets */ Pets;
export type ListPetsApiArg = {
  /** How many items to return at one time (max 100) */
  limit?: number;
};
export type CreatePetsApiResponse = /** status 201 Null response */ void;
export type CreatePetsApiArg = {
  pet: Pet;
};
export type ShowPetByIdApiResponse =
  /** status 200 Expected response to a valid request */ Pet;
export type ShowPetByIdApiArg = {
  /** The id of the pet to retrieve */
  petId: string;
};
export type Pet = {
  id: number;
  name: string;
  tag?: string;
};
export type Pets = Pet[];
export type Error = {
  code: number;
  message: string;
};
export const {
  useListPetsQuery,
  useLazyListPetsQuery,
  useCreatePetsMutation,
  useShowPetByIdQuery,
  useLazyShowPetByIdQuery,
} = injectedRtkApi;
