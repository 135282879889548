import React from 'react'
import { Outlet } from 'react-router-dom'

export default function AboutPage() {
  return (
    <>
        <div>AboutPage</div>
        <Outlet />
    </>
  )
}
