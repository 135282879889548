import React from 'react'
import VexoftPlaceholder from '../../../modules/components/VexoftPlaceholder'
import { VexoftAppCard, VexoftAppCardProps } from '../../../modules/components/VexoftAppCard'
import { Box, Container, Grid2 } from '@mui/material'
import CreateVexoftApp from '../../../modules/components/create-vexoft-app/CreateVexoftApp'

const availableVexoftApps: VexoftAppCardProps[] = [
  {
    appName: "Grafana",
    appUrl: "https://grafana.vexoft.com",
    appDescription: "Monitoring app",
    imageSrc: "/images/1200px-Grafana_logo.svg-1004x1024.png"
  },
  {
    appName: "Keycloak",
    appUrl: "https://keycloak.vexoft.com",
    appDescription: "User management app",
    imageSrc: "/images/Keycloak-logo.png"
  },
  {
    appName: "Kube-Dashboard",
    appUrl: "https://kubedashboard.vexoft.com",
    appDescription: "Kubernetes Cluster Dashboard",
    imageSrc: "/images/kubernetes-dashboard-ui.png"
  },

]

export default function HomePage() {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 3
    }}>
      <Grid2 container spacing={1} sx={{ flex: 1, paddingBottom: 16 }}>
        {availableVexoftApps.map(prop => <VexoftAppCard key={prop.appName} {...prop} />)}
      </Grid2>
      <Box sx={{ flex: 2 }}>
        {/* <VexoftPlaceholder /> */}
        <CreateVexoftApp />
      </Box>
    </Box>
  )
}
