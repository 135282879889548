import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

export interface VexoftAppCardProps {
  appName: string
  appUrl: string,
  appDescription: string
  imageSrc: string,
}

export const VexoftAppCard = (props: VexoftAppCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Card
      raised={true}
      sx={{
        width: isMobile ? '100%' : 230,
        height: isMobile ? 'auto' : 250,
        margin: isMobile ? 1 : 'auto',
        marginTop: isMobile ? 0 : 'auto',
      }}
      onClick={() => window.open(props.appUrl, '_blank')}
    >
      <CardActionArea disableRipple={true}>
        <Box sx={
          isMobile ?
            {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            } : {}
        }>
          {/* CardMedia on the left */}
          <CardMedia
            sx={{
              height: 120,
              width: isMobile ? 120 : undefined,
              flexShrink: isMobile ? 0 : undefined,
              backgroundSize: 'contain',
            }}
            image={props.imageSrc}
          />
          {/* CardContent on the right */}
          <CardContent sx={{ flex: 1 }}>
            <Typography gutterBottom variant="h5" component="div">
              {props.appName || 'appName'}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {props.appDescription || 'appDescription'}
            </Typography>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
}
