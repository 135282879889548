import React from 'react';
import { Box, Button, Typography, styled } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';

export interface CreateVexoftAppProps {}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function CreateVexoftApp(props: CreateVexoftAppProps) {
  return (
    <Box display="flex" alignItems="center">
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUpload />}
        sx={{ textTransform: 'none' }}
        onClick={() => alert("But not yet :(")}
      >
        Upload Openapi
        {/* <VisuallyHiddenInput
          type="file"
          accept=".yml,.yaml,.json"
          onChange={(event) => console.log(event.target.files)}
        /> */}
      </Button>
      
      <Typography variant="body1" sx={{ marginLeft: 2 }}>
        You can upload your OpenAPI file here and a fully functional REST API will be created for you.
      </Typography>
    </Box>
  );
}
