import React from 'react'
import { Outlet } from 'react-router-dom'
import HeaderBar from '../../modules/components/header-bar/HeaderBar'

export default function MainLayout() {
  return (
    <main style={{
      height: "100vh", 
      width: "100vw"
    }}>
      <HeaderBar />
      <Outlet />
    </main>
  )
}
