import { createTheme } from "@mui/material";

export const appTheme = createTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                   main: '#344966'
                }
            }
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        }
    }
})