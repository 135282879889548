import { Navigate, RouteObject } from "react-router-dom";
import AboutPage from "../pages/about/AboutPage";
import HomePage from "../pages/home/HomePage";
import PlaygroundPage from "../pages/playground/PlaygroundPage";
import MainLayout from "../pages/MainLayout";

export const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "home",
        element: <Navigate to="/" replace />
      },
      {
        path: "about",
        element: <AboutPage />,
        children: [
          {
            path: "nested",
            element: <PlaygroundPage />
          }
        ],
      },
      {
        path: "*",
        element: <div>Page not found 😢</div>
      }
    ]
  }
]