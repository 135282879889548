import { AccountCircle, Menu as MenuIcon } from '@mui/icons-material'
import {
  AppBar, Box, Button,
  IconButton,
  Menu, MenuItem,
  Toolbar, Typography
} from '@mui/material'
import { useKeycloak } from '@react-keycloak/web'
import React, { useEffect } from 'react'
// TODO replace import with lazy


export default function HeaderBar() {

  const { keycloak } = useKeycloak()

  useEffect(() => {
    console.log(keycloak.tokenParsed)
  }, [])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleLogout = (event: React.ChangeEvent<HTMLInputElement>) => {
    keycloak.logout()
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    keycloak.login()
  }

  return (
    <AppBar position="static" sx={{
      width: "100%",
      marginBottom: 1
    }}>
      <Toolbar>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}>
          <IconButton>
            <MenuIcon />
          </IconButton>
          {
            keycloak.authenticated ?
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
                <Typography>{keycloak.tokenParsed?.preferred_username}</Typography>
              </Box>
              :
              <Button color="inherit" onClick={handleLogin}>Login</Button>
          }
        </Box>
      </Toolbar>
    </AppBar>
  )
}